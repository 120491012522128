import { memo } from 'react'
import ReactInputMask from 'react-input-mask'
import type { FieldError, UseFormRegister } from 'react-hook-form'

interface Props {
  name: keyof {
    name: string
    email: string
    lastname?: string
    gender?: string
    mobile?: string
    dateBirth?: string
    document?: string
  }
  label?: string
  className?: string
  classContainer?: string
  mask?: string
  placeHolder?: string
  errors: FieldError | undefined
  register: UseFormRegister<{
    name: string
    email: string
    lastname?: string
    gender?: string
    mobile?: string
    dateBirth?: string
    document?: string
  }>
}
type InputProps = JSX.IntrinsicElements['input'] & Props

const Input = ({
  name,
  label,
  classContainer = '',
  className = '',
  mask,
  value,
  errors,
  defaultValue,
  type,
  placeHolder,
  required,
  disabled,
  register,
}: InputProps) => {
  return (
    <div
      className={`flex flex-col sm:w-1/2 w-full bg-[#ffffff] ${classContainer}`}
    >
      {label && (
        <label className={`text-[#333] pb-2 ${classContainer}`}>{label}</label>
      )}
      <ReactInputMask
        id={name}
        mask={mask ?? ''}
        value={value}
        placeholder={placeHolder}
        type={type}
        required={required}
        defaultValue={defaultValue}
        disabled={disabled}
        aria-invalid={!!errors}
        className={className}
        {...register(name)}
      />
      {errors && (
        <small className="mt-1 text-restructure-error font-inter">
          {errors.message}
        </small>
      )}
    </div>
  )
}

export { Input }
export default memo(Input)
