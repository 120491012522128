import { useRef, useState } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import { Link } from 'gatsby'
import type { DktSliderFowardRef } from 'src/components/restructure/common/DktSlider/DktSlider'
import DktSlider from 'src/components/restructure/common/DktSlider/DktSlider'

import Check from '../../../../../images/icons/Check'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import { slides } from './slidesContent'

type SliderComponentProps = {
  trackEvent: (trackEvent: string) => void
}

const SliderComponent = ({ trackEvent }: SliderComponentProps) => {
  const childRef = useRef<DktSliderFowardRef | null>(null)
  const { isMobile } = useMobile()
  const [activeSlide, setActiveSlide] = useState(0)

  const currentSlider = (sliderIndex: number) => setActiveSlide(sliderIndex)

  const handleActiveSlide = (index: number) => {
    setActiveSlide(index)
    childRef.current?.goToSlide(index)
  }

  return (
    <div className="pt-[60px] pr-[20px] pb-[20px] pl-[20px] sm:pt-[40px] sm:pr-[24px] sm:pl-[58px] sm:pb-[36px] border border-[#E1E4E7] rounded-[8px] mt-[-25px]  sm:mt-[0px] sm:-ml-[5px] w-5/5 sm:w-3/5">
      <span className="mr-[4px] mt-[20px] sm:mt-[0px] font-inter text-[#616161] text-[14px] text-base font-normal leading-6 ">
        Você sabia que tem direito a benefícios por ser membro clube? Confira
        alguns
      </span>

      <div className="mt-[40px] h-[267px]">
        <DktSlider
          showControls={false}
          autoplay={false}
          currentSlider={currentSlider}
          draggable={false}
          ref={childRef}
          sliderId="slider-component"
        >
          <div>
            {slides[0].benefits.map(({ title, links }, index) => {
              return (
                <div
                  key={`${title} - ${index}`}
                  className="mb-[20px] sm:ml-[0px]"
                >
                  <div className="flex gap-[5px] ">
                    <div className="w-[25px]">
                      <Check />
                    </div>
                    <span>{title}</span>
                  </div>

                  <div className="flex gap-2">
                    {links.map(({ text, to, trackEventText }, i) => {
                      return (
                        <Link
                          key={`${text} - ${i}`}
                          to={to}
                          className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                          onClick={() => trackEvent(trackEventText)}
                        >
                          {text}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            {slides[1].benefits.map(({ title, links }, index) => {
              return (
                <div key={`${title} - ${index}`} className="mb-[15px]">
                  <div className="flex gap-[5px] ">
                    <div className="w-[25px]">
                      <Check />
                    </div>
                    <span className="p">{title}</span>
                  </div>

                  <div className="flex gap-2">
                    {links.map(({ text, to, trackEventText }, i) => {
                      return (
                        <Link
                          key={`${text} - ${i}`}
                          to={to}
                          onClick={() => trackEvent(trackEventText)}
                          className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                        >
                          {text}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </DktSlider>
      </div>

      {!isMobile ? (
        <DesktopNavigation
          activeSlide={activeSlide}
          setActiveSlide={handleActiveSlide}
        />
      ) : (
        <MobileNavigation
          activeSlide={activeSlide}
          setActiveSlide={handleActiveSlide}
        />
      )}
    </div>
  )
}

export default SliderComponent
