import { Link } from 'gatsby'
import type { ReactNode } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import ArrowInternalLink from 'src/images/icons/ArrowInternalLink'
import { SmallLogo } from 'src/images/icons/SmallLogo'
import { sendEvent } from 'src/utils/restructure/analytics'

export const ExchangesContent = () => {
  const { isMobile } = useMobile()

  return (
    <div className="mb-20 mt-12 sm:m-0">
      <div className="flex flex-col items-center mb-12 gap-2 sm:flex-row sm:justify-between">
        <h1 className="text-2xl font-medium m-0">Trocas e Devoluções</h1>
        <Link
          to="/servicos/trocas-e-devolucoes/"
          className="flex items-center gap-3 text-sm font-semibold text-restructure-action"
        >
          Como funciona
          <ArrowInternalLink fill="#3643BA" width={6} height={9} />
        </Link>
      </div>

      <div className="flex flex-col gap-4 mb-16 sm:flex-row sm:gap-10">
        <ExchangesCard
          title={
            <>
              Trocar ou devolver podutos{' '}
              {isMobile ? (
                <b className="text-restructure-action">Decathlon</b>
              ) : (
                <SmallLogo />
              )}
            </>
          }
          description="Clique aqui se o produto que você deseja trocar ou devolver foi
            vendido e entregue pela Decathlon"
          link="https://decathlon.troquefacil.com.br/"
          eventAction="Trocar ou devolver podutos Decathlon"
        />
        <ExchangesCard
          title="Devolver produtos Parceiros"
          description="Clique aqui se o produto que você deseja devolver for de um vendedor
          diferente de Decathlon. (Troca não elegível)"
          link="https://decathlon.troquefacil.com.br/group"
          eventAction="Devolver produtos Parceiros"
        />
      </div>

      <h2 className="text-base font-semibold mb-4">
        Acompanhar minha solicitação aberta
      </h2>
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-10">
        <ExchangesCard
          title={
            <>
              Andamento de produto{' '}
              {isMobile ? (
                <b className="text-restructure-action">Decathlon</b>
              ) : (
                <SmallLogo />
              )}
            </>
          }
          eventAction="Andamento de produto Decathlon"
          link="https://decathlon.troquefacil.com.br/order"
        />
        <ExchangesCard
          title="Andamento de produtos Parceiros"
          eventAction="Andamento de produtos Parceiros"
          link="https://decathlonprodutosparceiros.troquefacil.com.br/order"
        />
      </div>
    </div>
  )
}

const ExchangesCard = ({
  title,
  description,
  link,
  eventAction,
}: {
  title: ReactNode
  description?: string
  link: string
  eventAction: string
}) => (
  <a
    href={link}
    target="_blank"
    className="flex-1"
    onClick={() => {
      sendEvent({
        event: 'event_track',
        event_category: 'clique',
        event_action: eventAction,
        event_label: 'my account - Minhas compras',
      })
    }}
    rel="noreferrer"
  >
    <div className="flex justify-between items-center py-5 px-6 border-gray border border-solid sm:px-8">
      <div className="flex flex-col gap-2">
        <h3 className="text-sm font-semibold sm:flex sm:gap-2 sm:items-center">
          {title}
        </h3>
        {description && (
          <p className="text-xs text-restructure-tertiary max-w-[280px] sm:text-sm">
            {description}
          </p>
        )}
      </div>
      <div className="flex flex-1 justify-end items-center">
        <button className="flex justify-center items-center w-8 h-8 rounded-round bg-restructure-icon-information">
          <ArrowInternalLink width={6} height={9} fill="#FFFFFF" />
        </button>
      </div>
    </div>
  </a>
)
