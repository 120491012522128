import type { InputHTMLAttributes } from 'react'

interface AddressInputProps extends InputHTMLAttributes<HTMLSelectElement> {
  label: string
  labelFor: string
  name: string
  readonly?: boolean
  className: string
}

const selectOptions = [
  { value: '', label: 'Selecione' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
]

const StateSelect = ({
  label,
  labelFor,
  name,
  readOnly = false,
  className,
  ...rest
}: AddressInputProps) => {
  return (
    <div className={`${readOnly && 'opacity-[.38]'} ${className}`}>
      <label className="flex flex-col gap-1 " htmlFor={labelFor}>
        <span className="font-inter text-sm font-normal">{label}</span>
        <select
          disabled={readOnly}
          className="address-input border border-neutral04 text-black font-inter text-sm py-5 px-4"
          name={name}
          id={labelFor}
          {...rest}
        >
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}

export default StateSelect
