import ArrowBack from '../../../../../images/icons/ArrowBack'
import ArrowNext from '../../../../../images/icons/ArrowNext'
import ArrowBackInactive from '../../../../../images/icons/ArrowBackInactive'
import ArrowNextInactive from '../../../../../images/icons/ArrowNextInactive'

type DesktopNavigationProps = {
  activeSlide: number
  setActiveSlide: (index: number) => void
}

const DesktopNavigation = ({
  activeSlide,
  setActiveSlide,
}: DesktopNavigationProps) => {
  return (
    <div className="w-full flex  justify-between items-center">
      <div className="flex">
        <div
          className={`${
            activeSlide === 0 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
          } w-[52px] h-[3px]  m-0`}
        />

        <div
          className={`${
            activeSlide === 1 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
          } w-[52px] h-[3px]  m-0 ml-[5px] `}
        />
      </div>
      <div className="flex gap-4 mr-[-10px]">
        <button
          onClick={() => {
            setActiveSlide(0)
          }}
        >
          {activeSlide === 0 ? <ArrowBackInactive /> : <ArrowBack />}
        </button>
        <button
          onClick={() => {
            setActiveSlide(1)
          }}
        >
          {activeSlide === 1 ? <ArrowNextInactive /> : <ArrowNext />}
        </button>
      </div>
    </div>
  )
}

export default DesktopNavigation
