import ArrowBack from '../../../../../images/icons/ArrowBack'
import ArrowNext from '../../../../../images/icons/ArrowNext'
import ArrowBackInactive from '../../../../../images/icons/ArrowBackInactive'
import ArrowNextInactive from '../../../../../images/icons/ArrowNextInactive'

type MobileNavigationProps = {
  activeSlide: number
  setActiveSlide: (index: number) => void
}

const MobileNavigation = ({
  activeSlide,
  setActiveSlide,
}: MobileNavigationProps) => {
  return (
    <div className="flex mt-[10px]  items-center">
      <div className="flex  w-full  gap-6">
        <button
          onClick={() => {
            setActiveSlide(0)
          }}
        >
          {activeSlide === 0 ? <ArrowBackInactive /> : <ArrowBack />}
        </button>

        <div className="w-full flex gap-4 items-center">
          <div
            className={`${
              activeSlide === 0 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
            }  w-full h-[3px]  m-0  items-center`}
          />

          <div
            className={`${
              activeSlide === 1 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
            }  w-full h-[3px]  m-0 ml-[5px] `}
          />
        </div>
        <button
          onClick={() => {
            setActiveSlide(1)
          }}
        >
          {activeSlide === 1 ? <ArrowNextInactive /> : <ArrowNext />}
        </button>
      </div>
    </div>
  )
}

export default MobileNavigation
